
import jsPDF from "jspdf";
import "jspdf-autotable";
const toPDF = (parsedData, fileName) => {
    const doc = new jsPDF("l", "pt", "a4");
    doc.autoTable({
        theme: "grid",
        styles: { valign: 'middle', halign: 'center', overflow: "linebreak", cellWidth: "auto" },
        columnStyles: { text: { cellWidth: "auto" } },
        head: [parsedData.headers],
        body: parsedData.columns,
    });
    doc.save(fileName + ".pdf");
}
const toPrint = (parsedData) => {
    const doc = new jsPDF("p", "pt", "a0");
    doc.autoTable({
        theme: "grid",
        styles: { valign: 'middle', halign: 'center', overflow: "linebreak", cellWidth: "auto" },
        columnStyles: { text: { cellWidth: "auto" } },
        head: [parsedData.headers],
        body: parsedData.columns,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
}

const toCSV = (parsedData, fileName) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += [
       parsedData.headers.join(","),
        ...parsedData.columns.map((item) => Object.values(item).join(",")),
    ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

    const data = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", fileName+".csv");
    link.click();
}

const toExcel = (parsedData, fileName) => {
    let csvContent = "data:text/xls;charset=utf-8,";
    csvContent += [
       parsedData.headers.join(","),
        ...parsedData.columns.map((item) => Object.values(item).join(",")),
    ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

    const data = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", fileName+".xls");
    link.click();
}

const copyJsonData = (data) =>{
    const el = document.createElement('textarea');
    var p ="";
    for(let k;k<data.columns.length; k++){
        p = p+ data.columns[k].join(" ")
    }
		el.value = JSON.stringify(data.headers.join(" ")  + (data.columns.map(a => a.join(" "))).join("\n") );

		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');  
		document.body.removeChild(el);
}
export { toPDF, toCSV, toExcel, copyJsonData, toPrint }