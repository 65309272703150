<template>
  <v-card flat>
    <v-card-title class="pa-0">
      <v-container fluid pa-0>
        <v-row no-gutters class="align-center">
          <v-col cols="6">
            <v-btn
              small
              tile
              class="elevation-0 mr-1"
              @click="copyDataToClipboard()"
              ><v-icon small left>content_copy</v-icon>Copy</v-btn
            >
            <v-btn small tile class="elevation-0 mr-1" @click="exportAsCSV"
              ><v-icon small left>description</v-icon> CSV
            </v-btn>
            <!-- <v-btn small tile class="elevation-0 mr-1" @click="exportAsExcel()"
              ><v-icon small left>note_add</v-icon> Excel
            </v-btn> -->

            <v-btn small tile class="elevation-0 mr-1" @click="exportAsPDF()"
              ><v-icon small left>picture_as_pdf</v-icon> PDF
            </v-btn>
            <v-btn small tile class="elevation-0 mr-1" @click="printData()"
              ><v-icon small left>print</v-icon> Print
            </v-btn>
            <v-menu :offset-y="true" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small tile class="elevation-0" v-bind="attrs" v-on="on">
                  <v-icon small left>view_column</v-icon> Select Columns
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group
                  v-model="selectedColumns"
                  multiple
                  color="primary"
                >
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          selectedColumns.length > 0
                            ? 'indigo darken-4'
                            : 'black'
                        "
                      >
                        {{ selectAllIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                  <v-list-item
                    v-for="(item, i) in headers"
                    :key="i"
                    :value="item"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              hide-details
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
      :headers="filteredHeaders"
      :items="events_data"
      :search="search"
      :footer-props="footerProps"
      ref="reportDataTable"
      class="pt-2"
    >
      <!-- @current-items="filteredItems" -->
      <template v-slot:[`item.TypeOfBooking`]="{ item }">
        <v-container fluid pa-0>
          <v-row no-gutters>
            <v-col>
              <v-chip :color="getColor(item.TypeOfBooking)" label small>
                {{ item.TypeOfBooking }}
              </v-chip>
            </v-col>
            <v-col cols="2">
              <v-icon
                class="ml-1"
                color="primary"
                small
                @click="openInfoDialog(item)"
              >
                info</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- <template v-slot:[`item.buildType`]="{ item }">
        {{ item.buildType == 0 || item.buildType == "" ? "-" : item.buildType }}
      </template> -->
      <template v-slot:[`item.contact`]="{ item }">
        {{ item.contact == "" ? "-" : item.contact }}
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.email == "" ? "-" : item.email }}
      </template>
      <template v-slot:[`item.exit_mode`]="{ item }">
        {{
          item.exit_mode == "" || item.exit_mode == null ? "-" : item.exit_mode
        }}
      </template>
      <template v-slot:[`item.orderID`]="{ item }">
        {{ item.orderID == "" || item.orderID == null ? "-" : item.orderID }}
      </template>
      <template v-slot:[`item.checkinDate`]="{ item }">
        {{ item.checkinDate + " " + item.checkinTime }}
      </template>
      <template v-slot:[`item.checkoutDate`]="{ item }">
        {{ item.checkoutDate + " " + item.checkoutTime }}
      </template>
    </v-data-table>
    <v-dialog v-model="infoDialog" max-width="800">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>Booking Details Information</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-3 black--text">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Customer Name
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.Username || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Email
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.email || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Contact No
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.contact || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Vehicle No
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.vehicleNo || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Order ID
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.orderID || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Booking ID (Divrt)
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.bid || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Type Of Booking
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.TypeOfBooking || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Status
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.status_text"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Amount ($)
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.price || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Payment Status
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.payment_status || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-show="selectedItem.status_text == 'Checked Out(F)'"
            >
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Force Checked Out By
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.forceCheckOut_by || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header><span class="font-weight-bold">Other Details</span></v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-2" style="background-color: #f4f6f9">
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        App
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="ltypes[selectedItem.ltype] || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        Model
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.model || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        OS Type
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.ostype || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        Manufacture
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.manufacture || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        OS Version
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.osversion || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col> </v-row
                    ><v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        SDK Version
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.sdk_version || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        App Version
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.appversion || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        Card Details
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.CardOnFile || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" class="elevation-0" @click="infoDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import * as parse from "@/utils/parseJsonData";
import * as exportData from "@/utils/exportData";

export default {
  name: "ReportTable",
  props: {
    events_data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    ltypes: {
      type: Object,
      default: function () {
        return {};
      },
    },
    type_color_codes: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      infoDialog: false,
      selectedItem: {},
      footerProps: { "items-per-page-options": [10, 25, 50, 100] },
      selectedColumns: [],
      reportOption: "",
      search: "",
      headers: [
        {
          text: "Order ID",
          align: "center",
          value: "orderID",
        },
        { text: "Booking ID (Divrt)", value: "bid", align: "center" },
        { text: "Email ID", value: "email", align: "center" },
        { text: "Contact No", value: "contact", align: "center" },
        { text: "Amount   ($)", value: "price", align: "center" },
        // { text: "License Plate", value: "vehicleNo", align: "center" },
        {
          text: "Check In Date",
          value: "checkinDate",
          align: "center",
          width: "12%",
        },
        // { text: "Check In Time", value: "checkinTime", align: "center" },
        { text: "Check In Mode", value: "entry_mode", align: "center" },
        {
          text: "Check Out Date",
          value: "checkoutDate",
          align: "center",
          width: "12%",
        },
        // { text: "Check Out Time", value: "checkoutTime", align: "center" },
        { text: "Check Out Mode", value: "exit_mode", align: "center" },
        { text: "Type", value: "TypeOfBooking", align: "center", width: "10%" },
        // { text: "Environment", value: "buildType", align: "center" },
        { text: "Status", value: "status_text", align: "center" },
      ],
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((s) => this.selectedColumns.includes(s));
    },
    selectAllIcon() {
      if (this.selectedColumns.length == this.headers.length) return "close";
      if (
        this.selectedColumns.length != this.headers.length &&
        this.selectedColumns.length > 0
      )
        return "remove";
      return "check_box_outline_blank";
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    // this.selectedColumns.push("SelectAll")
  },
  methods: {
    openInfoDialog(item) {
      this.infoDialog = true;
      this.selectedItem = Object.assign({}, item);
    },
    getColor(type) {
      return this.type_color_codes[type]; //Object.keys(this.type_color_codes).find(a => a
    },
    exportAsCSV() {
      let filteredData = this.$refs.reportDataTable.$children[0].filteredItems;
      let parsedData = parse.parseColumnData(
        this.selectedColumns,
        filteredData
      );
      exportData.toCSV(parsedData, "Report Tansactional History");
    },
    copyDataToClipboard() {
      let filteredData = this.$refs.reportDataTable.$children[0].filteredItems;

      let parsedData = parse.parseColumnData(
        this.selectedColumns,
        filteredData
      );
      exportData.copyJsonData(parsedData);
      this.$notify({
        group: "divrt",
        title: "Success",
        text: "Copied to clipboard successfully",
        type: "success",
      });
    },
    printData() {
      let filteredData = this.$refs.reportDataTable.$children[0].filteredItems;

      let parsedData = parse.parseColumnData(
        this.selectedColumns,
        filteredData
      );
      exportData.toPrint(parsedData);
    },
    exportAsPDF() {
      let filteredData = this.$refs.reportDataTable.$children[0].filteredItems;

      let parsedData = parse.parseColumnData(
        this.selectedColumns,
        filteredData
      );
      exportData.toPDF(parsedData, "Report Tansactional History");
    },
    exportAsExcel() {
      let filteredData = this.$refs.reportDataTable.$children[0].filteredItems;
      let parsedData = parse.parseColumnData(
        this.selectedColumns,
        filteredData
      );
      exportData.toExcel(parsedData, "Report Tansactional History");
    },
    // filteredData(e) {
    //   console.log(e);
    //
    // },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedColumns.length - 1 == this.headers.length) {
          this.selectedColumns = [];
        } else {
          this.selectedColumns = this.headers.slice();
        }
      });
    },
  },
};
</script>
<style lang="sass">
</style>