<template>
  <v-container fluid>
    <v-row no-gutters class="py-2">
      <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6" class="pr-2" >
        <type-overview :events_data="reportData" :loading="loading" card-text="Type Overview">
        </type-overview>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6" >
        <total-revenue
          :events_data="reportData"
          :loading="loading"
        ></total-revenue>
      </v-col>
    </v-row>
    <v-card flat class="mb-1 pa-2">
      <v-row no-gutters>
        <v-col cols="12" sm="12" xs="12" md="4" lg="3" xl="3" class="">
          <divrt-date-range
            v-model="reportDateRange"
            display-format="MM-dd-yyyy"
            no-title
            :input-props="inputProps"
            :menu-props="menuProps"
            :presets="presets"
            :action-labels="{ apply: 'Apply' }"
          >
          </divrt-date-range>
        </v-col>
      </v-row>
    </v-card>
    <v-row no-gutters>
      <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
        <v-card flat>
          <v-card-title>
            <span>Transactional History</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <report-table
              :events_data="reportData"
              :type_color_codes="typeColorCodes"
              :ltypes="ltypes"
            ></report-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as API from "@/api/api";
import { mapGetters } from "vuex";
import { EventBus } from "@/lib/EventBus";
import ReportTable from "../components/report/ReportTable.vue";
import TypeOverview from "@/components/home/TypeOverview.vue";
import TotalRevenue from "@/components/home/TotalRevenue.vue";
import DivrtDateRange from "@/uiComponents/DateRange.vue";
import { presets, ISO_FORMAT } from "@/utils/constants";
import { format, subDays } from "date-fns";
export default {
  name: "ReportsView",
  components: { ReportTable, DivrtDateRange,TypeOverview, TotalRevenue},
  data() {
    return {
      // dateRangeMenu: false,
      // dateRange: ["2021-01-01", "2021-01-31"],
      reportData: [],
      ltypes: {},
      typeColorCodes: {},
      reportDateRange: {
        start: format(subDays(new Date(), 7), ISO_FORMAT),
        end: format(subDays(new Date(), 0), ISO_FORMAT),
      }, //{ "start": "2021-02-18", "end": "2021-02-27" }
      inputProps: { outlined: true, flat: true },
      menuProps: { offsetY: true, closeOnContentClick: false },
      presets: presets,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //   });
  // },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
    }),
    loading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"]("REPORTS");
    },
    // dateRangeText() {
    //   return this.dateRange.join(" ~ ");
    // },
  },
  watch: {
    reportDateRange() {
      this.fetchHistoryStastsData();
      // this.fetchLastWeekBookings();
    },
  },
  async created() {},
  async mounted() {
    this.fetchHistoryStastsData();
    EventBus.$on("zone-changed", () => {
      this.fetchHistoryStastsData();
    });
  },
  methods: {
    async fetchHistoryStastsData() {
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "REPORTS",
          pending: true,
        });
        const reports = this.selectedZone?.zid
          ? await API.getHistoryByZone({
              zid: this.selectedZone.zid,
              start: this.reportDateRange.start,
              end: this.reportDateRange.end,
            })
          : {};
          this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "REPORTS",
          pending: false,
        });
        // this.$notify({
        //   group: "divrt",
        //   title: "Success",
        //   text: "Fetched reports successfully",
        //   type: "success",
        // });
        this.reportData = reports?.data?.history ? reports.data.history : [];
        this.ltypes = reports?.data?.ltypes ? reports.data.ltypes : {};
        let btypes = reports?.data?.bookingtypes
          ? reports.data.bookingtypes
          : [];
        let mappedCodes =
          reports?.data?.colorCodes && reports?.data?.bookingtypes
            ? reports.data.colorCodes.map((c, i) => ({
                [btypes[i]]: c,
              }))
            : [];
        this.typeColorCodes = Object.assign({}, ...mappedCodes);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
